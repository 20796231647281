// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-Ygl6osF2_xqb6wCYng6Swy7YDsEPruU",
  authDomain: "ggat-space.firebaseapp.com",
  projectId: "ggat-space",
  storageBucket: "ggat-space.appspot.com",
  messagingSenderId: "261043663947",
  appId: "1:261043663947:web:c3f78fc35341357fe7c9ce",
  measurementId: "G-KRB9V7PCD6"
};

if(process.env.NODE_ENV === 'production') {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // eslint-disable-next-line no-unused-vars
  const analytics = getAnalytics(app);
}
