import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Welcome to Giga's space!</h1>
      <ul>
        <li>
          <a href='https://github.com/ggat' rel="noreferrer" target='_blank'>Github</a>
        </li>
        <li>
          <a href='https://www.linkedin.com/in/giga-gatenashvili'  rel="noreferrer" target='_blank'>LinkedIn</a>
        </li>
        <li>
          <a href='https://medium.com/@gatenag'  rel="noreferrer" target='_blank'>Medium</a>
        </li>
      </ul>
    </div>
  );
}

export default App;
